/*
 * @Author: your name
 * @Date: 2022-03-12 14:03:01
 * @LastEditTime: 2023-10-22 14:06:23
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\axios\index.js
 */
import axios from 'axios'; // 引入axios
import qs from 'qs'; // 引入qs模块，用来序列化post类型的数据
// import { Dialog } from 'vant';
import store from '../store/store'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus';

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/';
}
else if (process.env.NODE_ENV === 'production') {
    if (process.env.VUE_APP_CURRENTMODE === 'test') {
        // axios.defaults.baseURL = 'https://api_admin.1688center.com';
        axios.defaults.baseURL = 'https://apiadmin.gghh0.com';//倍投
    }
    if (process.env.VUE_APP_CURRENTMODE === 'test-cecil') {
        axios.defaults.baseURL = 'http://adm.dist.com';
        // axios.defaults.baseURL = 'http://scale.djl.com';//倍投
    }
    if (process.env.VUE_APP_CURRENTMODE === 'dj5568') {
        axios.defaults.baseURL = 'https://apiadmin.dj5568.com';
        // axios.defaults.baseURL = 'https://apiadmins.dj5568.com';//倍投
    }
    if (process.env.VUE_APP_CURRENTMODE === 'aoliu') {
        axios.defaults.baseURL = 'https://apiadmin.zy226.com';
        // axios.defaults.baseURL = 'https://apiadmins.zy226.com';//倍投
    }
    if (process.env.VUE_APP_CURRENTMODE === 'xinaoliutwo') {
        axios.defaults.baseURL = 'https://apiadmin.ab55.one';
        // axios.defaults.baseURL = 'https://apiadmins.ab55.one';//倍投
    }
    if (process.env.VUE_APP_CURRENTMODE === 'kuaicai1') {
        axios.defaults.baseURL = 'https://apiadmin.ab6.one';
        // axios.defaults.baseURL = 'https://apiadmins.ab6.one';//倍投
    }
    if (process.env.VUE_APP_CURRENTMODE === 'ab138') {
        axios.defaults.baseURL = 'https://apiadmin.ab138.one';
        // axios.defaults.baseURL = 'https://apiadmins.ab138.one';//倍投
    }
}
// console.log(window.location.hostname);
// const hostname = window.location.hostname;
// if (hostname == 'localhost') {
//     axios.defaults.baseURL = '/';
// }
// if (hostname == 'new.1688center.com') {
//     axios.defaults.baseURL = 'https://api_admin.1688center.com';
// }
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
/**修改配置信息，跨域保存session值 */
axios.defaults.withCredentials = true;

// 设置了请求拦截器，处理下post方法传的参数格式问题
axios.interceptors.request.use(
    config => {
        //当查看下级模式时，增删改接口不能请求
        if (store.state.watchsub_show) {
            let cant_request_list = [
                'trader/update_rate',
                'user_version2/member_save',
                'user_version2/update_tuishui',
                'user_version2/member_status',
                'account/credit_recharge_kc',
                'user_version2/user_info_save',
                'check/check_add',
                'check/check_edit',
                'play/auto_ball_rate',
                'play/bonus_set_exe',
                'play/auto_ball_rate_exe',
                'play/bonus_member',
                'play/bonus_set_member_exe4',
                'play/game_checked',
                'play/system_init_set',
                'play/system_init_set_alone',
                'play/news_edit',
                'play/news_insert',
                // 'play/news',
            ]
            if (cant_request_list.includes(config.url)) {
                const CancelToken = axios.CancelToken;
                const source = CancelToken.source();
                config.cancelToken = source.token;
                source.cancel("取消请求");
                ElMessage.error('查看下级模式中，不能修改下级数据！')
            }
        }
        // 利用qs转换了一下data的格式，让post方法能够正确识别传参
        config.data = config.method == 'post' ? qs.stringify(config.data) : config.data;
        // console.log(config);
        if (config.url !== 'login/ajax_login' && config.url !== 'login/vcode' && config.url !== 'version.json') {
            //拼接token
            config.url = `${config.url}${config.url.includes('?') ? '&' : '?'}${store.state.url_query_info}`;
        }
        return config;
    },
    error => {
        // 这里出现错误可能是网络波动造成的，清空 pendingRequests 对象
        pendingRequests.clear();
        return Promise.reject(error);
    }
)

axios.interceptors.response.use(
    res => {
        // console.log(res);
        if (res.data.resultCode === -201 && res.data.status === -1) {
            ElMessage.error(res.data.shortMessage)
            window.location.replace('/')
        }
        else if (res.data.status === -1) {
            if (res.data.shortMessage === '用户未登录' || res.data.shortMessage === '已在其他pc端登录') {
                window.location.replace('/');
            } else {
                ElMessageBox.confirm(res.data.shortMessage, "提示信息", {
                    customClass: 'myMessageBox',
                    confirmButtonText: "确定",
                    type: "error",
                    callback: action => { }
                });
            }
        }
        return res;
    },
    error => {// 对响应错误做处理
        return Promise.reject(error);
    }
);
export default axios;