/*
 * @Author: your name
 * @Date: 2022-03-11 16:27:32
 * @LastEditTime: 2023-10-13 17:32:46
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE/
 * @FilePath: \pc_admin\src\main.js
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import axios from './axios/axios'
import VueAxios from 'vue-axios'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons'
import locale from 'element-plus/lib/locale/lang/zh-cn'

import { time } from './filters/time'
import { capAmountToString } from './filters/capAmountToString'
import { stringlength } from './filters/stringlength'
const app = createApp(App);

//自定义全局过滤器
app.config.globalProperties.$filters = { time, capAmountToString, stringlength }

for (const name in ElIcons) {
    app.component(name, ElIcons[name])
}
app.use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(ElementPlus, { locale })
    .mount('#app')