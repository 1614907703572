/*
 * @Author: your name
 * @Date: 2022-03-11 21:09:56
 * @LastEditTime: 2023-10-20 22:21:08
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\store\store.js
 */
import { createStore } from 'vuex'

export default createStore({
    state: {
        app_version: '',
        url_query_info: '',
        userinfo: {
            user_id: '',
            kj_url: '',
        },
        themeColor: '',
        themeColorhalf: '',
        themeColorLight: '',
        game_list: [],
        gid: '',
        instant_gameinfo: '',
        instant_checked_gid: '',
        instant_checked_gname: '',
        activated_sup: 'notice',
        notice_tc: {//公告弹窗
            create_time: '0',
            text: '',
        },
        int_router_view: false,//初始化路由页面
        instant_pk: '0',
        viewdata: {},
        watchsub_show: false,
        watchsub_sub_id: false,
        watchsub: {
            group_name: '',
            username: '',
        },
        relod_sub_menu_data: false,//是否刷新小菜单数据
        route_data: {
            zidongbuhuosheding: { list: [], play_arr: {} }
        },
        instant_cacheData: {
            tm: null,
            tma: null,
            tmb: null,
            sb: null,
            tx: null,
            zm: null,
            ztm1: null,
            ztm2: null,
            ztm3: null,
            ztm4: null,
            ztm5: null,
            ztm6: null,
            zm1_6: null,
            lma: null,
            lma1: null,
            lma2: null,
            lma3: null,
            lma4: null,
            lma5: null,
            yx: null,
            yxbz: null,
            bz5: null,
            bz6: null,
            bz7: null,
            bz8: null,
            bz9: null,
            bz10: null,
            bz11: null,
            bz12: null,
            lx2: null,
            lx3: null,
            lx4: null,
            lx5: null,
            lx2bz: null,
            lx3bz: null,
            lx4bz: null,
            lx5bz: null,
            lxyz: null,
            lm: null,
            ws: null,
            wsbz: null,
            wl: null,
            wlbz: null,
            sxws: null,
        },
        //顶部信息
        top_data: null,
        menulist: [],
        now_menu: '',
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    },
})
