<!--
 * @Author: your name
 * @Date: 2022-03-11 16:27:32
 * @LastEditTime: 2023-10-19 16:20:07
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \pc_admin\src\App.vue
-->
<template>
    <router-view @get_color="get_color" />
</template>

<script>
const app_version = require("../public/version.json");
export default {
    data() {
        return {
            themeColor: "",
            themeColorhalf: "",
            themeColorLight: "",
        };
    },
    created() {
        // 在页面加载时读取sessionStorage里的状态信息
        if (sessionStorage.getItem("store")) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem("store"))));
        }
        //在页面刷新时将vuex里的信息保存到sessionStorage里
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            window.addEventListener("unload", () => {
                window.sessionStorage.setItem("store", JSON.stringify(this.$store.state));
            });
        } else {
            window.addEventListener("beforeunload", () => {
                window.sessionStorage.setItem("store", JSON.stringify(this.$store.state));
            });
        }

        this.themeColor = this.$store.state.themeColor;
        this.themeColorhalf = this.$store.state.themeColorhalf;
        this.themeColorLight = this.$store.state.themeColorLight;
        // console.log(this.axios.defaults.baseURL);

        //检测版本，刷新页面获取最新代码
        if (process.env.NODE_ENV !== "development") {
            let _this = this;
            let time = 20;
            let sid = "";
            let old_version = app_version.version;
            window.setInterval(() => {
                // console.log(app_version.version);
                this.axios.get(`https://${window.location.host}/version.json`, {}, { headers: { "Cache-Control": "no-cache" } }).then((result) => {
                    if (old_version != result.data.version) {
                        console.log(`老版本时间号：${old_version}`);
                        console.log(`新版本时间号：${result.data.version}`);
                        this.$nextTick(() => {
                            _this.$notify({
                                title: "系统更新提示",
                                type: "success",
                                showClose: false,
                                dangerouslyUseHTMLString: true,
                                message: `<div class="redcolor">将在  ${time} 秒后自动刷新页面</div><div>请注意保存页面数据或操作</div><div class="refbtn" onClick="window.location.reload()">立即刷新</div>`,
                                customClass: "version-notify",
                                duration: 0,
                            });
                            sid = setInterval(() => {
                                time -= 1;
                                console.log(time);
                                if (time == 0) {
                                    clearInterval(sid);
                                    window.location.reload();
                                    console.log("已刷新");
                                }
                            }, 1000);
                        });
                    }
                });
            }, 60000);
        }
    },
    methods: {
        get_color(themeColor, themeColorLight, themeColorhalf, themeColorhalf_l) {
            // console.log(themeColor, themeColorLight, themeColorhalf, themeColorhalf_l);s
            this.themeColor = this.$store.state.themeColor = themeColor;
            this.themeColorhalf = this.$store.state.themeColorhalf = themeColorhalf;
            this.themeColorLight = this.$store.state.themeColorLight = themeColorLight;
            this.themeColorhalf_l = this.$store.state.themeColorhalf_l = themeColorhalf_l;
            // console.log(this.themeColor, this.themeColorhalf, this.themeColorLight);
            this.axios.post("user_version2/user_colors", { color: this.themeColor }).then((result) => {
                if (result.data.status == 200) {
                    // this.$message.success(result.data.shortMessage);
                }
            });
        },
    },
};
</script>
<style>
body {
    margin: 0;
    background-color: #eceff0;
}
* {
    font-size: 12px;
    -webkit-text-size-adjust: none !important;
}
#app {
    font-family: Verdana, "宋体", Arial, Sans !important;
    -webkit-font-smoothing: antialiased !;
    -moz-osx-font-smoothing: grayscale !;
    text-align: center;
    font-size: 12px;
}

:root {
    --el-color-primary: v-bind(themeColor) !important;
}
.white-text {
    color: #fff;
}
.black-text {
    color: #000;
}
.theme-text {
    color: v-bind(themeColor);
}
.theme-text-half {
    color: v-bind(themeColor);
}
.theme-border {
    border-color: v-bind(themeColor) !important;
}
.half-border {
    border-color: v-bind(themeColorhalf) !important;
}
.theme-border-lhc-jszd-ykpl {
    text-align: center;
    line-height: 20px;
    border-bottom: 2px solid v-bind(themeColor) !important;
    cursor: pointer;
}

.theme-border-half {
    border-color: v-bind(themeColorhalf) !important;
}

.theme-bg {
    background-color: v-bind(themeColor);
    border-color: v-bind(themeColor) !important;
}
.theme-half-bg {
    background-color: v-bind(themeColorhalf);
}
.light-bg {
    background-color: v-bind(themeColorLight);
}
.fff-light-bg {
    background-image: linear-gradient(#fff, v-bind(themeColorLight));
}

.themeColorhalf-bg {
    background-color: v-bind(themeColorhalf);
    border-color: v-bind(themeColorhalf) !important;
}

.light-text {
    color: v-bind(themeColorLight);
}
.el-popper.is-light {
    border: 1px solid #000;
}
.el-popper.is-light .el-popper__arrow::before {
    border: 1px solid #000 !important;
}
.user .el-tooltip__trigger {
    display: inline-block;
    width: 100%;
}
/* .pl_popver */
.loseinfo .el-divider__text {
    background-color: v-bind(themeColorLight) !important;
    font-weight: bold;
    font-size: 12px;
    color: v-bind(themeColor);
    padding: 0 5px;
}
.pl_popver {
    position: absolute;
    width: 140px;
    z-index: 3;
    background-color: v-bind(themeColorLight);
    border: 1px solid v-bind(themeColor);
}
.pl_popver_title {
    background-color: v-bind(themeColor);
    color: #fff;
    text-align: center;
    line-height: 25px;
}
.el-divider--horizontal {
    margin: 15px 0;
}
/* el-popver */
.my_popver_pl {
    /* --el-popover-padding: 0 !important; */
    position: relative;
    z-index: 9999;
}
/* .el-message-box */
.myMessageBox {
    width: 220px !important;
    padding: 0;
}

.el-message-box__title span {
    background: url("./assets/title_icon.gif") 5px center no-repeat;
    font-weight: bold;
    padding-left: 25px;
    font-size: 12px;
}
.myMessageBox .el-message-box__headerbtn {
    top: 3px;
    right: 5px;
}
.myMessageBox .el-message-box__header {
    padding: 10px 5px;
}
.myMessageBox .el-message-box__content {
    background-color: #fff;
    border-top: 1px solid;
    border-bottom: 1px solid;
    border-color: #ccc;
}
.myMessageBox .el-message-box__btns {
    padding: 5px;
    padding-bottom: 0;
}
.myMessageBox .el-message-box__btns .el-button {
    width: 60px;
    height: 30px;
}
.myMessageBox .el-message-box__btns .el-button--primary {
    background-color: #f07700;
    border-color: #f07700;
}
/* mydialogclass */
.mydialogclass {
    background-color: v-bind(themeColorLight) !important;
    border: 2px solid v-bind(themeColor);
    text-align: left;
}
.mydialogclass .el-dialog__header {
    padding: 10px;
}
.mydialogclass .el-dialog__body {
    background-color: #fff !important;
    border-top: 1px solid v-bind(themeColorhalf);
    border-bottom: 1px solid v-bind(themeColorhalf);
    padding: 10px;
}
.mydialogclass .el-dialog__footer {
    padding: 6px;
    text-align: center;
}
.mydialogclass .el-dialog__headerbtn {
    width: 35px;
    height: 35px;
    top: 0px;
    color: v-bind(themeColorhalf) !important;
}
.mydialogtitle {
    background: url("./assets/title_icon.gif") 5px center no-repeat;
    font-weight: bold;
    padding-left: 25px;
    color: #000;
}
/* myform */
.myform {
    border: 1px solid v-bind(themeColorhalf);
    margin-top: 3px;
    line-height: 28px;
}

.myform .myform-item:hover .el-col {
    background-color: #ffff2b !important;
}

.myform-item-lable {
    text-align: right;
    padding: 0 10px;
    background-color: v-bind(themeColorLight);
    border-right: 1px solid v-bind(themeColorhalf);
    border-bottom: 1px solid v-bind(themeColorhalf);
}

.myform-item-value {
    padding: 0 10px;
    text-align: left;
    border-bottom: 1px solid v-bind(themeColorhalf);
    background-color: #fff;
}

.myform:nth-last-child(1),
.myform:nth-last-child(2) {
    border-bottom: 0;
}

/* el-select */
.home .el-select {
    --el-select-input-focus-border-color: #e6a23c;
}
.el-select-group__title {
    color: red !important;
}
.subuser .subuser-select .el-input {
    --el-input-border-color: v-bind(themeColor);
}
.subuser .subuser-select .el-input .el-input__wrapper {
    padding: 2px 7px;
}
/* el-input */
.home .el-input {
    --el-input-focus-border-color: #e6a23c;
}

.home .el-textarea__inner:focus {
    box-shadow: 0 0 0 1px #e6a23c;
}

/* el-checkbox */
.home .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: v-bind(themeColor);
}

/* el-button */
#app .el-button--primary {
    --el-button-text-color: v-bind(themeColorLight) !important;
    --el-button-bg-color: v-bind(themeColor) !important;
    --el-button-border-color: v-bind(themeColor) !important;
    --el-button-hover-text-color: v-bind(themeColorLight) !important;
    --el-button-hover-bg-color: v-bind(themeColor) !important;
    --el-button-hover-border-color: v-bind(themeColorLight) !important;
}

.home .top {
    background-color: v-bind(themeColor);
    border: 1px solid v-bind(themeColor);
    border-bottom-color: #aaa;
}

.home .el-button--small {
    --el-button-size: 22px;
    height: var(--el-button-size);
    padding: 5px;
    font-size: 12px;
    border-radius: calc(var(--el-border-radius-base) - 1px);
}

.home .el-link {
    font-size: 12px;
    color: v-bind(themeColor);
}

.home .el-link:hover {
    color: red;
}

.home .el-radio__input.is-checked .el-radio__inner {
    background-color: v-bind(themeColor);
}

.home .el-radio__input.is-checked + .el-radio__label {
    color: v-bind(themeColor);
}

.home .el-input-group__append,
.home .el-input-group__prepend {
    background-color: v-bind(themeColorLight);
}

.home .el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
    background-color: v-bind(themeColor);
    color: v-bind(themeColorLight);
}

/* el-table */
.home .el-table {
    font-size: 12px;
    --el-table-border-color: v-bind(themeColorhalf);
    /* --el-table-border: 1px solid v-bind(themeColorhalf); */
    --el-table-text-color: var(--el-text-color-regular);
    --el-table-header-text-color: #333;
    --el-table-row-hover-bg-color: #ffff2b;
    --el-table-current-row-bg-color: #ffff6d;
    --el-table-header-bg-color: v-bind(themeColorLight);
    --el-table-fixed-box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
    --el-table-bg-color: var(--el-fill-color-blank);
    --el-table-tr-bg-color: #fff;
    --el-table-expanded-cell-bg-color: var(--el-fill-color-blank);
    --el-table-fixed-left-column: inset 10px 0 10px -10px rgba(0, 0, 0, 0.15);
    --el-table-fixed-right-column: inset -10px 0 10px -10px rgba(0, 0, 0, 0.15);
    color: #000;
}

.home .el-table tr:hover {
    background-color: #ffff2b;
}

/* .home .el-table tr:nth-child(even) {
                                                    background-color: v-bind(themeColorLight);
                                                } */
.home .report .el-table tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 0);
}

/* .home .instant .el-table tr > td:first-child {
                                                    background-color: v-bind(themeColorLight);
                                                } */

.home .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell :hover {
    background: #ffff2b;
}

.home .el-table .el-table__cell {
    padding: 3px 3px;
    word-break: break-all;
}
.report .el-table .el-table__cell {
    padding: 3px 1px;
}
.home .el-table .cell {
    padding: 0;
    line-height: 22px;
}

.home .el-table .table-header-cell {
    color: #333;
    line-height: 22px;
    background-image: linear-gradient(#fff, v-bind(themeColorLight));
}

.home .el-table--border .el-table__cell:first-child .cell {
    padding-left: 0;
}

.el-table__footer {
    font-weight: bold;
}
.home .el-table__footer-wrapper {
    border-top: 0;
}
.gameDetail .el-table__footer-wrapper {
    border-top: var(--el-table-border);
}
.el-table__footer-wrapper tbody td {
    background-color: #fff !important;
}
.lhc .el-table .themeColorhalf-row {
    --el-table-tr-bg-color: v-bind(themeColorhalf_l) !important;
}
.lhc .el-table .gray-row {
    --el-table-tr-bg-color: #eceff0;
}
/* .el-radio-button */
.home .el-radio-button {
    --el-radio-button-checked-bg-color: v-bind(themeColor);
    --el-radio-button-checked-text-color: #fff;
    --el-radio-button-checked-border-color: v-bind(themeColor);
}

.home .el-radio-button__inner {
    color: #000;
}

.el-radio-button--small .el-radio-button__inner {
    padding: 6px 10px !important;
}

/* el-check-tag */
.home .el-check-tag.is-checked {
    background-color: v-bind(themeColor);
    color: #fff;
}

.home .el-check-tag.is-checked:hover {
    background-color: v-bind(themeColorLight);
    color: #333;
}

.home .el-check-tag {
    background-color: transparent;
    color: #000;
    font-size: 12px;
    line-height: var(--el-font-size-base);
    padding: 3px;
    transition: var(--el-transition-all);
    font-weight: normal;
}

.el-icon {
    vertical-align: middle;
}

/* 日期 */
.el-date-editor.el-input--small.el-input--suffix .el-input__inner {
    padding-right: 0;
    font-weight: bold;
}

.my-date-picker .el-date-picker {
    width: 220px;
}

.my-date-picker .el-date-picker .el-date-table tr td {
    width: 27px !important;
    height: 22px !important;
    padding: 0px !important;
}

.my-date-picker .el-date-picker .el-picker-panel__content {
    width: 215px;
    margin: 0px;
}

.my-date-picker .el-date-picker .el-date-picker__header {
    margin: 3px;
}

/* 分页 */
.paging {
    height: 24px;
    position: relative;
    top: calc(50% - 50px);
    left: calc(50% - 150px);
}

#app .el-pagination {
    --el-pagination-bg-color: none;
}

.el-pagination button:disabled {
    background: none !important;
}

.el-pager li {
    background: none;
}

.el-pagination .btn-next,
.el-pagination .btn-prev {
    color: #000;
    background: none;
}

.home .router-view-main {
    padding: 3px 0;
    overflow: hidden;
}

.home .panel .panel-body {
    padding: 3px;
}

.home .panel .panel-title {
    height: 28px;
    line-height: 28px;
    padding: 0 3px;
    text-align: left;
    background-image: linear-gradient(#fff, v-bind(themeColorLight));
    border-bottom: 1px solid v-bind(themeColorhalf);
}

.home .panel .panel-title h2 {
    font-size: 12px;
    font-weight: bold;
    background: url("./assets/title_icon.gif") no-repeat 0 8px;
    padding: 0 0 0 18px;
    margin: 0 0 0 5px;
    float: left;
}

.home .panel {
    border: 1px solid v-bind(themeColorLight);
    box-shadow: 0 2px 5px #ccc;
    background-image: linear-gradient(#fff, 90%, v-bind(themeColorLight));
    padding-bottom: 10px;
    margin: 0 auto;
}

.home .sub-menu {
    background-image: linear-gradient(#fff, v-bind(themeColorLight));
}

body .myConfirmButtonClass {
    background-color: v-bind(themeColor);
}

.redcolor {
    color: red;
}

.blackcolor {
    color: #000;
}

/* a-icon */
a:hover {
    color: red;
}

.a-icon {
    height: 18px;
    line-height: 18px;
    background-repeat: no-repeat;
    background-image: url("./assets/iconlist.gif");
    float: left;
    padding-left: 19px;
    margin-right: 5px;
    vertical-align: middle;
    white-space: nowrap;
    text-decoration: none;
}

.i-rizhi {
    background-position: 0 1px;
}

.i-three {
    background-position: 0 -36px;
}

.i-six {
    background-position: 0 -90px;
}

.i-changeLog {
    background-position: 0 -72px;
}

.i-topUp {
    background: url("./assets/chongzhi.gif") no-repeat 2px;
}

.i-cashOut {
    background: url("./assets/tixian.png") no-repeat 2px;
}

.i-cashFlow {
    background: url("./assets/xianjinliu.png") no-repeat 2px;
}

.i-tuishui {
    background: url("./assets/tuishui.png") no-repeat 2px;
}

.i-Edit {
    background-position: 0 -54px;
}
.version-notify {
    width: 200px !important;
    padding: 5px 0 !important;
}
.version-notify .el-notification__icon {
    width: 40px !important;
    height: 100px !important;
}
.version-notify .el-icon svg {
    width: 30px !important;
    height: 30px !important;
}
.version-notify .el-notification__group {
    margin: 0 !important;
}
.version-notify .refbtn {
    width: 100% !important;
    background-color: #009933;
    color: #fff;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
}
</style>
